
// https://uxplanet.org/how-to-customize-bootstrap-b8078a011203

// Override default variables before the import
//$body-bg: #282C34;

@import '~bootstrap/scss/bootstrap.scss';

body {
    font-family: 'Roboto', sans-serif;
}

.nav-tabs .nav-link {
    cursor: pointer;
}
